var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('h3',{staticClass:"mb-10"},[_c('v-icon',{staticClass:"back-button",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")]),_vm._v(" Detail ")],1)]),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showCreateBilling = true}}},[_vm._v(" Create Billing ")])],1),_c('FormBilling',{attrs:{"loan-id":_vm.loanSubId},model:{value:(_vm.showCreateBilling),callback:function ($$v) {_vm.showCreateBilling=$$v},expression:"showCreateBilling"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Search billing","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('HeaderInfo',{staticStyle:{"margin-left":"0","margin-right":"0","margin-bottom":"2rem"},attrs:{"titles":_vm.titles,"values":_vm.values,"color":_vm.disbursementStatusColor[_vm.loanSub.loanSub.approvalStatus]}}),_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"headers":_vm.headers,"items":_vm.rawBillings,"item-key":"id","show-select":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-col',[_vm._v(" "+_vm._s(_vm.formatToIDRCurrency(item.amount))+" ")])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('v-col',[_vm._v(" "+_vm._s(_vm.$moment(new Date(item.dueDate)).format('DD MMM YYYY HH:mm'))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"no-gutters":""}},[(item.status.toLowerCase() === 'pending')?_c('div',{staticStyle:{"color":"#FCBE2D"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status.toLowerCase() === 'paid')?_c('div',{staticStyle:{"color":"blue"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e(),(item.status.toLowerCase() === 'late')?_c('div',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(item.status)+" ")]):_vm._e()]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"action-btn me-4 text-capitalize",style:(_vm.isDark
            ? { 'border-color': '#5E5669AD' }
            : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.toDetailInvoice(item.id)}}},[_vm._v(" View invoice ")]),(
            _vm.formatBillingType(item.invoice) === 'manual' )?_c('v-btn',{staticClass:"action-btn me-4 text-capitalize",style:(_vm.isDark
            ? { 'border-color': '#5E5669AD' }
            : { 'border-color': 'lightgray' }),attrs:{"small":"","outlined":"","color":"primary"}},[_c('v-icon',{attrs:{"small":"","dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBlockHelper)+" ")])],1):_vm._e()],1):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }