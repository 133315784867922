<template>
  <div>
    <v-row align="center">
      <h3
        class="mb-10"
      >
        <v-icon
          class="back-button"
          style="cursor: pointer;"
          @click="$router.go(-1)"
        >
          {{ icons.mdiChevronLeft }}
        </v-icon> Detail
      </h3>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="showCreateBilling = true"
        >
          Create Billing
        </v-btn>
      </v-col>
      <FormBilling
        v-model="showCreateBilling"
        :loan-id="loanSubId"
      />
      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search billing"
          single-line
          hide-details
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <HeaderInfo
      :titles="titles"
      :values="values"
      :color="disbursementStatusColor[loanSub.loanSub.approvalStatus]"
      style="margin-left: 0; margin-right: 0; margin-bottom: 2rem;"
    />
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="rawBillings"
      item-key="id"
      show-select
      class="elevation-1 mt-10"
      :search="search"
    >
      <template v-slot:item.amount="{item}">
        <v-col>
          {{ formatToIDRCurrency(item.amount) }}
        </v-col>
      </template>
      <template v-slot:item.dueDate="{item}">
        <v-col>
          {{ $moment(new Date(item.dueDate)).format('DD MMM YYYY HH:mm') }}
        </v-col>
      </template>
      <template v-slot:item.status="{item}">
        <v-col
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <div
            v-if="item.status.toLowerCase() === 'pending'"
            style="color:#FCBE2D"
          >
            {{ item.status }}
          </div>
          <div
            v-if="item.status.toLowerCase() === 'paid'"
            style="color:blue"
          >
            {{ item.status }}
          </div>
          <div
            v-if="item.status.toLowerCase() === 'late'"
            style="color:green"
          >
            {{ item.status }}
          </div>
        </v-col>
      </template>
      <template v-slot:item.action="{item}">
        <v-row
          v-if="$vuetify.breakpoint.smAndUp"
          no-gutters
        >
          <v-btn
            class="action-btn me-4 text-capitalize"
            small
            outlined
            :style="isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }"
            color="primary"
            @click="toDetailInvoice(item.id)"
          >
            View invoice
          </v-btn>

          <v-btn
            v-if="
              formatBillingType(item.invoice) === 'manual' "
            class="action-btn me-4 text-capitalize"
            small
            outlined
            :style="isDark
              ? { 'border-color': '#5E5669AD' }
              : { 'border-color': 'lightgray' }"
            color="primary"
          >
            <v-icon
              small
              dense
            >
              {{ icons.mdiBlockHelper }}
            </v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import {
  mdiPlus,
  mdiMagnify,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiAccountGroup,
  mdiDotsHorizontalCircle,
  mdiChevronDown,
  mdiChevronLeft,
  mdiBlockHelper,
} from '@mdi/js'
import FormBilling from './components/FormBilling.vue'
import HeaderInfo from '../../manage-salary/components/HeaderInfo.vue'

export default {
  name: 'ManageBilling',
  components: { FormBilling, HeaderInfo },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    const disbursementStatusColor = {
      done: '#44A780',
      inProgress: '#FCBE2D',
      Approved: '#9E9E9E',
      rejected: '#FF6666',
      NeedApproval: '#999999',
    }

    return {
      isDark,
      search,
      disbursementStatusColor,
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
        mdiAccountGroup,
        mdiDotsHorizontalCircle,
        mdiChevronDown,
        mdiChevronLeft,
        mdiBlockHelper,
      },
    }
  },
  data() {
    return {
      selected: [],
      titles: ['Loan name', 'Recipient', 'Due date', 'Remaining Loan', 'Status'],
      values: [],
      headers: [
        {
          text: 'Billing name',
          align: 'start',
          value: 'serviceType',
        },
        { text: 'Status', align: 'center', value: 'status' },
        { text: 'Total paid', align: 'start', value: 'amount' },
        { text: 'Payment Method', align: 'start', value: 'invoice.payment_method' },
        { text: 'Due dates & time', align: 'start', value: 'dueDate' },
        { text: 'Action', align: 'start', value: 'action' },
      ],

      showCreateBilling: false,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    rawBillings() {
      return this.$store.getters['manageLoan/getBillingByLoanId']
    },
    loanSubId() {
      return this.$route.params.idLoanSub
    },
    loanSub() {
      return this.$store.getters['manageLoan/getLoanSub']
    },
  },
  async created() {
    await this.getBilllingByLoanId()
    await this.getLoanSubById()

    this.values = [
      this.loanSub.loanSub.name,
      this.loanSub.loanSub.recipient.name,
      this.formatDate(this.loanSub.loanSub.dueDate),
      this.formatToIDRCurrency(this.loanSub.loanSub.remainLoanBase),
      this.loanSub.loanSub.approvalStatus,
    ]
    //console.log(this.loanSub, 'loan sub')
  },
  methods: {
    async getBilllingByLoanId() {
      try {
        await this.$store.dispatch('manageLoan/getBillingByLoanId', {
          loanId: this.loanSubId,
        })
      } catch (error) {
        console.log(error, 'errr')
      }
    },
    formatToIDRCurrency(number) {
      return number ? new Intl.NumberFormat('id-ID').format(number) : 0
    },
    toDetailInvoice(idBilling) {
      return this.$router.push({ path: `/manage-loan/manage-billing/detail-invoice-billing/${idBilling}` })
    },
    async getLoanSubById() {
      try {
        await this.$store.dispatch('manageLoan/getLoanSubById', {
          idLoan: this.loanSubId,
          sub_id: this.user.sub_id,
        })
      } catch (error) {
        console.log(error, 'errr')
      }
    },
    formatBillingType(invoice) {
      let temp = null
      let result = null
      if (invoice.description) {
        temp = invoice.description.split(' ')
        result = temp[temp.length - 1].split('-')[0]
      }

      return result
    },
    formatDate(date) {
      return this.$moment(new Date(date)).format('DD MMM YYYY HH:mm')
    },
  },
}
</script>

<style>
</style>
