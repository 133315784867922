<template>
  <v-row
    :style="`background: ${color}14;`"
    class="header-info"
  >
    <v-col
      v-for="(title, i) in titles"
      :key="i"
      cols="auto"
      class="header-item"
      :style="i < titles.length - 1 ? 'border-right: 1px solid #d1cdcd;' : ''"
    >
      <div
        class="item-title"
        :style="{ color: isDark ? 'white' : 'black' }"
      >
        {{ title ? title : "--" }}
      </div>
      <div
        class="item-value"
        :style="title === 'Status' ? `color: ${statusColor[values[i]]}` : 'color: #9E9E9E'"
      >
        {{ title === 'Status' ? statusText[values[i]] : (values[i] ? values[i] : "--") }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  name: 'HeaderInfo',
  setup() {
    const { isDark } = useAppConfig()
    const statusText = {
      done: 'Done',
      inProgress: 'In progress',
      approved: 'Approved',
      rejected: 'Rejected',
      waitingApproval: 'Waiting Approval',
      NeedApproval: 'NeedApproval',
      Approved: 'Approved',
    }
    const statusColor = {
      done: '#44A780',
      inProgress: '#FCBE2D',
      approved: '#757575',
      rejected: '#FF6666',
      waitingApproval: '#999999',
      NeedApproval: '#999999',
      Approved: '#757575',
    }

    return { statusText, statusColor, isDark }
  },
  props: {
    titles: { type: Array, default: () => [] },
    values: { type: Array, default: () => [] },
    color: { type: String, default: '#999999' },
  },
}
</script>

<style lang="scss" scoped>
.header-info {
  border-radius: 8px;
  margin-top: 24px;
}

.header-item {
  padding: 0 2.5rem;
  margin: 16px 0;
}

.item-title {
  color: #999999;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 1rem;
}

.item-value {
  color: #111b21;
  font-size: 16px;
  line-height: 20px;
}
</style>
