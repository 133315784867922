<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="620px"
  >
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-col cols="1">
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon size="20">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-col>

          <v-col align="center">
            <h5>Create Billing</h5>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-form
        ref="form"
        @submit.prevent="createBilling"
      >
        <v-card-text class="pt-8 px-8">
          <v-snackbar
            v-model="showSnackbarErr"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 5 !important"
          >
            {{ errMessage }}
            <template #action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="closeShowSnackbarErr()"
              >
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-text-field
            v-model="amountPaid"
            label="Amount Paid"
            outlined
            :rules="[amountRules.validateExceedRemainLoanBase(loanSub.loanSub.remainLoanBase)]"
            type="number"
            :hint="amountHint"
          >
            <!-- amountCannotExceedRemainLoanBaseRule -->
          </v-text-field>
          <v-text-field
            v-model="
              description"
            label="Description"
            outlined
          >
          </v-text-field>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            type="submit"
            :loading="isLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable prefer-destructuring */
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'

export default {
  name: 'FormBilling',
  props: {
    value: { type: Boolean, required: false },
    loanId: {
      type: String,
      default() {
        return null
      },
    },
  },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      isDark,
      search,
      icons: {
        mdiClose,
      },
    }
  },
  data() {
    return {
      amountPaid: null,
      description: null,
      billing: {},
      showSnackbarErr: false,
      amountCannotBeEmptyRule: [value => !!value || 'Amount paid wajib diisi'],
      amountCannotExceedRemainLoanBaseRule: [
        value => value <= this.loanSub.loanSub.remainLoanBase || 'Amount tidak boleh diatas 7000000',
      ],
      amountRule: [
        value => value <= this.loanSub.loanSub.remainLoanBase || 'Amount tidak boleh diatas 7000000',
        value => !!value || 'Amount paid wajib diisi',
      ],
      amountRules: {
        validateExceedRemainLoanBase(amount) {
          return v => v <= +amount || 'Tidak boleh lewat woy'
        },
      },
      errMessage: '',
      isLoading: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    project() {
      return this.$store.getters['manageSalary/getCurrentProject']
    },
    rawBillings() {
      return this.$store.getters['manageLoan/getBillingByLoanId']
    },
    loanSub() {
      return this.$store.getters['manageLoan/getLoanSub']
    },
    amountHint() {
      return `Tidak boleh diatas ${this.loanSub.loanSub.remainLoanBase}`
    },
  },
  async created() {
    //console.log(this.rawBillings, 'rawBillings')
    //console.log(this.$route.params.idLoanSub)
    //console.log(this.loanSub, 'loanSub')
  },
  methods: {
    async createBilling() {
      const isValid = this.$refs.form.validate()
      //console.log(isValid)
      if (!isValid) return
      if (+this.amountPaid > +this.loanSub.remainLoanBase) {
        this.showSnackbarErr = true
        this.errMessage = 'Cannot create billing, your remain loan is not that much'
      } else if (+this.amountPaid <= 0) {
        this.showSnackbarErr = true
        this.errMessage = 'Amount paid invalid'
      } else {
        this.isLoading = true
        await this.$store.dispatch('manageLoan/createBilling', {
          data: {
            subId: this.user.sub_id,
            loanId: this.loanId,
            amount: this.amountPaid,
            description: this.description,
            userEmail: this.project.email,
            payerInfo: this.project.channel.phone_number,
            type: 'Pembayaran Cicilan DP',
          },
        })
        this.isLoading = false
        this.dialog = false
        await this.$store.dispatch('manageLoan/getBillingByLoanId', {
          loanId: this.loanId,
        })

        this.amountPaid = ''
      }
    },
    closeShowSnackbarErr() {
      this.showSnackbarErr = false
    },
  },
}
</script>

<style>
</style>
